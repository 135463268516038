import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Dropdown, Menu } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { isMobile } from "../utils/helper";
import MobileFollow from "./MobileFollow";
import Follow from "./Follow";
import "./Layout.scss";
import icon from "../assets/home/logo.png";

const items = [
  {
    label: <Link to="/test">首页</Link>,
    key: "/test",
  },
  {
    label: <Link to="/article/list">企业动态</Link>,
    key: "/article/list",
  },
  {
    label: <Link to="/test/us">关于我们</Link>,
    key: "/test/us",
  },
];

const Layout = ({ children }) => {
  const location = useLocation();
  const [current, setCurrent] = useState(location.pathname);

  useEffect(() => {
    setCurrent(location.pathname);
  }, [location.pathname]);

  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };
  console.log(current);
  return (
    <div className="layout">
      <header className="my-navbar">
        <div className="wrapper">
          <div className="wp">
            <Link to="/test">
              <img className="icon" src={icon} />
            </Link>
            {isMobile() ? (
              <Dropdown menu={{ items }}>
                <div>
                  <MenuOutlined className="menu-icon" />
                </div>
              </Dropdown>
            ) : (
              <Menu
                onClick={onClick}
                selectedKeys={[current]}
                mode="horizontal"
                items={items}
              />
            )}
          </div>
        </div>
      </header>
      <div>{children}</div>
      {isMobile() ? <MobileFollow /> : <Follow />}
    </div>
  );
};

export default Layout;
