import React from "react";
import { Helmet } from "react-helmet";
import { isMobile } from "../../../utils/helper";
import "./Detail.scss";

const Detail = ({ data: { title, date, keywords, htmlContent } }) => {
  const back = () => {
    window.history.back();
  };

  let html = htmlContent
    ? htmlContent.replace(/href="https/g, `onclick="return false;";" `)
    : "";

  html = `<div class="xunfu-article">${html}</div>`;
  return (
    <div className="detail-page">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={`${title} ${keywords}`} />
      </Helmet>
      {/* <div className="navbar">
        <div className="back" onClick={back}>
          <LeftOutlined />
        </div>
        <div className="name">查看内容</div>
      </div> */}
      <article>
        <header className="article-header">
          <h1 className="title">{title}</h1>
          <div className="date">{date}</div>
          {isMobile() && <div className="keywords">{keywords}</div>}
        </header>
        <section className="article-content">
          <div
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
        </section>
      </article>
    </div>
  );
};

export default Detail;
