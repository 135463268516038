import React, { Suspense, lazy } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import Report from "./pages/Report/Report";
import { isMobile } from "./utils/helper";
import ErrorBoundary from "./components/ErrorBoundary"; // 导入 ErrorBoundary 组件
import ArticleList from "./pages/Article/ArticleList";
import ArticlePage from "./pages/Article/ArticlePage";
import JiYu from "./pages/JiYu/jiyu";

const Entry = lazy(() => import("./pages/Entry/Entry"));
const Complaints = lazy(() => import("./pages/Complaints/Complaints"));
const ComplaintsDetail = lazy(() => import("./pages/Complaints/Detail"));

const Pag = lazy(() => import("./pages/PAG/PAG"));
const City = isMobile() ? lazy(() => import("./pages/City/City")) : Pag;
const Us = lazy(() => import("./pages/Us/us"));
const UserAgreement = lazy(() => import("./pages/UserAgreement/UserAgreement"));
const UserPrivacy = lazy(() => import("./pages/UserPrivacy/UserPrivacy"));
const DownLoad = lazy(() => import("./pages/Download/download"));
const Bridge = lazy(() => import("./pages/Bridge/Bridge"));
const Home = isMobile()
  ? lazy(() => import("./pages/Mobile/Entry"))
  : lazy(() => import("./pages/Test/Entry"));

const TestUs = isMobile()
  ? lazy(() => import("./pages/Mobile/AboutUs"))
  : lazy(() => import("./pages/Test/AboutUs"));

function App() {
  return (
    <div className="app">
      <HashRouter>
        <ErrorBoundary>
          {/* 在这里包裹整个路由结构 */}
          <Suspense>
            <Routes>
              <Route path="/" element={<Entry />} />
              <Route path="us" element={<Us />} />
              <Route path="/test" element={<Home />} />
              <Route path="/test/us" element={<TestUs />} />
              <Route path="/test/jiyu" element={<JiYu />} />
              <Route path="/article/list" element={<ArticleList />} />
              <Route path="/article/:id" element={<ArticlePage />} />
              <Route path="report" element={<Report />} />
              <Route path="complaints/detail" element={<ComplaintsDetail />} />
              <Route path="complaints" element={<Complaints />} />
              <Route path="bridge" element={<Bridge />} />
              <Route path="pag" element={<Pag />} />
              <Route path="city" element={<City />} />
              <Route path="us" element={<Us />} />
              <Route path="userAgreement" element={<UserAgreement />} />
              <Route path="userPrivacy" element={<UserPrivacy />} />
              <Route path="download" element={<DownLoad />} />
              <Route path="*" element={<div className="dom-404"></div>} />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </HashRouter>
    </div>
  );
}

export default App;
