import { Button } from "antd";
import "./Follow.scss";
import Weixin from "../assets/home/follow/weixin.png";
import Gzh from "../assets/home/follow/gzh.png";

const Follow = () => {
  const jump = () => {
    window.open(
      "https://www.zhipin.com/gongsi/0296a085924a97991Xx43di7GFs~.html?ka=search_rcmd_company_0296a085924a97991Xx43di7GFs~_custompage",
      "_blank"
    );
  };
  return (
    <div className="follow">
      <div className="trust">
        <div className="title">相信改变的力量</div>
        <div className="row">
          <div className="item">加入我们</div>
          <div className="item">虚位以待</div>
        </div>
        <Button type="primary" onClick={jump}>
          社招入口
        </Button>
      </div>
      <div className="bottom">
        <div className="left">
          <div className="qcode">
            <div className="weixin">
              <img src={Weixin} className="pic" />
              <div className="desc">询赋小秘书</div>
            </div>
            <div className="gzh">
              <img src={Gzh} className="pic" />
              <div className="desc">询赋公众号</div>
            </div>
          </div>
        </div>
        <div className="right">
          <div>
            <p>北京询赋科技有限公司</p>
            <p>
              地址：北京市海淀区丰贤中路7号北京市科学技术研究院现代制北京市计算中心
            </p>
            <p>邮箱：Beijingxunfukeji@163.com</p>
            <br />
          </div>
          <footer className="footer">
            <p>© 2024 询赋科技. All rights reserved.</p>
            <p className="small">备案号： 京ICP备2024089314号</p>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Follow;
