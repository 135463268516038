import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Upload, message } from "antd";
import axios from "axios";
import "./FileUpload.scss";
import { xunfuBridge } from "../utils/bridge";

// 上传接口 URL
const url = "http://192.168.1.137:8921/api/file/upload";

const authToken = xunfuBridge.getToken();

const FileUpload = ({ max = 4, onChange }) => {
  const [fileList, setFileList] = useState([]);

  // 上传文件前的操作
  const beforeUpload = (file) => {
    // 检查文件类型是否为 JPG 或 PNG
    const isJpgOrPng = file.type === "image/jpg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("只能上传 JPG/PNG 文件!");
      return Upload.LIST_IGNORE;
    }

    // 检查文件列表是否已达到最大数量
    if (fileList.length >= max) {
      message.error(`最多只能上传 ${max} 张图片`);
      return Upload.LIST_IGNORE; // 阻止文件上传
    }

    // // 添加文件到文件列表并触发上传
    // setFileList((prevFileList) => [...prevFileList, file]);
    customUpload(file); // 自动上传文件
    return false; // 阻止默认上传
  };

  // 自定义上传
  const customUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);

    // 自定义请求头
    const headers = {
      authToken: xunfuBridge.getToken(),
      "Content-Type": "multipart/form-data",
    };

    try {
      const response = await axios.post(url, formData, { headers });
      const {
        result: { url: imageUrl, thumbUrl: thumbnailUrl },
      } = response.data; // 假设接口返回的图片 URL 和缩略图 URL
      console.log(response);
      // message.success(`${file.name} 上传成功！`);

      // 将上传成功的文件信息添加到 fileList
      const newFile = {
        uid: file.uid,
        name: file.name,
        status: "done",
        url: imageUrl, // 用于父组件的完整 URL
        preview: thumbnailUrl, // 用于当前组件的预览缩略图 URL
      };
      const newList = [...fileList, newFile];
      // 更新文件列表
      setFileList(newList);

      // 将完整图片 URL 返回给父组件
      if (onChange) onChange([...newList.map((item) => item.url)]);
    } catch (error) {
      message.error(`${file.name} 上传失败`);
      console.error(error);
    }
  };

  // 上传按钮
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
    </button>
  );

  return (
    <div className="file-upload">
      <Upload
        beforeUpload={beforeUpload}
        listType="picture-card"
        fileList={fileList.map((file) => ({ ...file, url: file.preview }))}
        onRemove={(file) => {
          // 从文件列表中移除文件
          const newFileList = fileList.filter((item) => item.uid !== file.uid);
          setFileList(newFileList);

          // 同步更新父组件，移除相应的图片 URL
          if (onChange) onChange(newFileList.map((item) => item.url));
        }}
        showUploadList={{ showRemoveIcon: true }}
      >
        {fileList.length >= max ? null : uploadButton}
      </Upload>
    </div>
  );
};

export default FileUpload;
