import article10 from "./article10.json";
import article11 from "./article11.json";
import article12 from "./article12.json";
import article13 from "./article13.json";
import article14 from "./article14.json";
import m1 from "../assets/meeting/m1.png";
// 模拟文章内容数据
const articles = {
  14: {
    title: "威海询赋私董会：​引领数字产业经济新潮流",
    keywords:
      "在这个充满机遇与挑战的时代，人工智能（AI）技术正以惊人的速度飞速发展。它广泛应用于各行各业，从生产自动化到智能化决策，再到个性化定制，加速改变着传统经济模式，催生了智能制造、自动驾驶和智能医疗等新兴商 ...",
    htmlContent: article14,
    pic: m1,
    date: "2024.07.03",
  },
  13: {
    title: "张西文：熟人链接 • 价值共创 | 日照询赋私董会专题访谈（二）",
    keywords:
      "张西文：熟人链接 • 价值共创 | 日照询赋私董会专题访谈导语：日照询赋私董会于2024年7月26日正式成立。该私董会致力于深度耕耘熟人社交领域，着力优化成员社交网络结构，为成员提供资源整合、人脉连接、产业投资等 ...",
    htmlContent: article13,
    date: "2024.11.02",
  },
  12: {
    title: "张西文：熟人链接 • 价值共创 | 日照询赋私董会专题访谈(一）",
    keywords:
      "张西文：熟人链接 • 价值共创 | 日照询赋私董会专题访谈导语：日照询赋私董会于2024年7月26日正式成立。该私董会致力于深度耕耘熟人社交领域，着力优化成员社交网络结构，为成员提供资源整合、人脉连接、产业投资等 ...",
    htmlContent: article12,
    date: "2024.11.01",
  },
  11: {
    title: "询赋招募城市合伙人",
    keywords: "询赋招募城市合伙人",
    htmlContent: article11,
    date: "2024.10.16",
  },
  10: {
    title: "询赋威海私董会人工智能分享座谈会",
    keywords: "询赋威海私董会人工智能分享座谈会",
    htmlContent: article10,
    date: "2024.09.16",
  },
};
export default articles;
