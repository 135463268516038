import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Detail from "./components/Detail";
import articles from "../../config/data";
import Layout from "../../components/Layout";
import "./ArticlePage.scss";

const ArticlePage = () => {
  // 获取文章 ID
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
    });
  }, []);

  const data = articles[id];

  if (!data) {
    return <p>文章不存在</p>;
  }

  return (
    <Layout>
      <div className="article-page">
        <Detail data={data} />
      </div>
    </Layout>
  );
};

export default ArticlePage;
