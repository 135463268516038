import Axios from "axios";

export const IM_BASE_URL = "http://192.168.88.232:8088";

const instance = Axios.create({
  baseURL: "http://192.168.1.107:7083/api",
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    Accept: "application/json;charset=utf-8",
  },
});

instance.interceptors.response.use((config) => {
  const {
    data: { code, retMsg, data, token },
  } = config;
  return { data, code };
});

export default instance;
