import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import articles from "../../config/data";
import { isMobile } from "../../utils/helper";
import "./ArticleList.scss";

const ArticleList = () => {
  return (
    <Layout>
      <div className="article-list">
        {Object.keys(articles)
          .reverse()
          .map((key, index) =>
            index === 0 && !isMobile() ? (
              <Link to={`/article/${key}`}>
                <div className="hot-item">
                  <div className="hot-top">
                    <div className="title">{articles[key].title}</div>
                    <div className="date">{articles[key].date}</div>
                  </div>
                  <div className="hot-bottom">
                    <img src={articles[key].pic} className="pic" />
                    <div className="keywords">{articles[key].keywords}</div>
                  </div>
                </div>
              </Link>
            ) : (
              <Link to={`/article/${key}`}>
                <div className="item">
                  <div className="title">{articles[key].title}</div>
                  <div className="date">{articles[key].date}</div>
                </div>
              </Link>
            )
          )}
      </div>
    </Layout>
  );
};

export default ArticleList;
