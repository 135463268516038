import React, { useState, useEffect } from "react";
import ai1 from "../../assets/home/ai1.png";
import ai2 from "../../assets/home/ai2.png";
import Layout from "../../components/Layout";
import "./jiyu.scss";

const config = {
  items: [
    {
      image: ai1,
      content: "未来药房坐标山东威海，全国覆盖率达80%",
    },
    {
      title: "医药行业",
      content:
        "未来药房坐标山东威海，全国覆盖率达80%。未来药房坐标山东威海，全国覆盖率达80%。",
    },
    {
      image: ai2,
      content: "未来药房坐标山东威海，全国覆盖率达80%。",
    },
  ],
};

const JiYu = () => (
  <Layout>
    <div className="jiyu-page">
      <div className="main">
        <div className="my-title">未来机域</div>
        <div className="list">
          {config.items.map((item, index) => (
            <article className="item" key={index} to="jiyu">
              {item.title && <div className="item-title">{item.title}</div>}
              {item.image && (
                <div className="pic">
                  <img src={item.image} />
                </div>
              )}
              <div className="content">{item.content}</div>
            </article>
          ))}
        </div>
      </div>
    </div>
  </Layout>
);

export default JiYu;
